import React, { Component } from "react";
import { withRouter, errorHandler } from "../../utils/GlobalFunctions";
import { Tabs, Popover } from "antd";
import { getBaseURL } from "../../utils/axiosRequest";
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from "moment";
import { isMobile } from "react-device-detect";


export class PaymentForms extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          data: null,
          requestFailed: false,
          userforms: null,

        };
    }

    componentDidMount() {
        this.getUsersForm();
    }
    
    componentDidUpdate() {}

    getUsersForm = ()=>{
        const today = moment(); 
        var forms = sessionStorage.getItem("Forms")
        
        if (forms === null || forms === undefined || forms === 'undefined' || forms === 'null') {
            this.getForms();
        //this.setState({ userforms : forms, isLoaded: true });  
        }
        else {
            forms =  JSON.parse(forms)
            if (forms[0].loaded !== today.format('YYYY-MM-DD')){
                this.getForms();
            } else {
                this.setState({ userforms : forms, isLoaded: true }); 
            }
        }
    
        
    
    }

    getForms=()=>{
        fetch(`${getBaseURL()}forms/userforms`, 
      { 
          method: "GET",
          headers: { 
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + window.sessionStorage["access_token"]
          },
      })
  
      .then((response) => {
        return errorHandler(response, 'PaymentForms.jsx - getUserForm', this.props.navigate)
      })
          .then((responseJson) => {
            sessionStorage.setItem("Forms", JSON.stringify(responseJson));
            this.setState({ userforms : responseJson, isLoaded: true });
            
          });
    }

render() {
    const {isLoaded, userforms} = this.state;
    
return (
    
<div className="w-full">{!isLoaded 
    ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
    : 
    <div>
        <div className="md:bg-[#18526B] md:sticky md:top-0 z-[99] flex justify-center p-[1rem] sm:text-base md:text-white uppercase">Рӯйхати хизматрасониҳо</div>
        <Tabs
            
            defaultActiveKey="1"
            tabPosition={isMobile ? "top" : "left"}
            items={userforms.map((group, i) => {
            const id = String(i + 1);
            return {
                label: (
                    group.group_name.length > 38 ? 
                    <Popover overlayStyle={{maxWidth: '300px'}} content={group.group_name} title={''} trigger="hover">
                        <div className="flex h-auto w-[300px]">
                            <div className="truncate">{`${group.group_name}`}</div>
                        </div>
                    </Popover> 
                    : <div  className="flex h-auto md:w-[300px]">
                        <div className="truncate sm:px-4 md:px-0">{`${group.group_name}`}</div>
                    </div>

                    
                    
                ),
                key: id,
                children: (group.forms.map((item, id) => {
                    
                    return(
                        <div key={`tbitem_${id}`} className="pt-[1.2rem] w-full justify-center flex">
                            <Link className="w-full" to={`/payments/forms/${item.form_id}`} 
                                state={{formItem: item}} 
                            > 
                            <div className="flex items-center md:w-[80%] min-h-[64px] bg-white p-[20px] m-2 shadow-lg rounded-[8px] border-[1px]">
                                {`${item.form_name.tj}`}
                            </div>
                            </Link>
                        </div>
                    )     
                }))

            };
            })}
        />
    </div>
}</div>
)
}

}

export default withRouter(PaymentForms);