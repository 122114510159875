import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Modal, Form} from 'antd';
import jwt_decode from "jwt-decode";
import { deviceDetect } from "react-device-detect";

export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function shortQuantity(param) {
  let firstLetter = param.slice(0, 1);
  let lastLetters = param.slice(-2);
  return isMobile ? `${firstLetter}-${lastLetters}` : param;
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function API_KEY() {
  return 'bfb9f8d8071553ff521ed26bcd0452e5d06e36c92284cc253608550f8af7d757'
}


export function errorHandler (response, function_name, navigate) {

  if (response.status === 200) {
    return response.json()}
  else if(response.status === 401 || response.status === 403) {
    const isLogined = sessionStorage.getItem('isLogged');
    destroyToken();
    if (isLogined) {Modal.warning({
      title:'Сессия тамом шуд!',
      content: (<div>Вақти сессияи браузер тамом шуд. Илтимос ба система аз дигар ворид шавед!</div>),
    });
  } else {
    Modal.error({
      title:'Воридшавии нодуруст!',
      content: (<div>Логин ёки рамз хато ворид карда шуд!</div>),
    });
  }
    
    navigate('/');
  }
  else if(response.status === 409 || response.status === 422) {
    response.json()
      .then(respjson =>{
    
    const err =  respjson
    const device = JSON.parse(JSON.stringify(deviceDetect()));
    
    Modal.error({
      onOk() {
        /*console.log(JSON.stringify({
          'api_url': response.url,
          'function_name': function_name, 
          'server_error': {'device': device, 'error': err.detail},
          'status': response.status,
        }))*/
      fetch(`https://smartpay.tj/api/billing/error`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.sessionStorage["access_token"],
        },
        body: JSON.stringify({
          'api_url': response.url,
          'function_name': function_name, 
          'server_error': err.detail,
          'device': device,
          'status': response.status,
        })
      })
    },
    
      title:'Амалиёти нодуруст!',
      content: (
        <div className='flex w-full mb-2'>
          Ба дархости шумо аз тарафи сервер чавоби нодуруст омад. 
          Барои баланд бардоштани сифати барнома, маълумотҳои техникӣ ба Smartpay равон карда мешавад.
        </div>),
      
    });
    });
    navigate('/payments');
    return null
  }  
  else {
    Modal.warning({
      title:'Дархости нодуруст!',
      content: (<div>Дархост дар тарафи сервер нодуруст иҷро шуд!</div>),
    });
    
  }
}

export function getBaseURL() {
  let hostname = window.location.hostname
  /* console.log(hostname) */
  return process.env.REACT_APP_API_URL
}

export function getToken() {
  
  try {
    const access_token = sessionStorage.getItem("access_token");
    return jwt_decode(access_token);
  } catch (error) {}
}

export function destroyToken() {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("isLogged");
  //   sessionStorage.removeItem("refresh_token");
}


export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  let location = useLocation();
  const [form]= Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation("", "", "", { useSuspense: false });

  return <WrappedComponent {...props} 
      params={params} 
      translation={t} 
      location={location} 
      form = {form}
      navigate = {navigate}
    />;
};
